<template>
  <div>
    <HeadSection
      permissionOfSection="deeplinks"
      :breadcrumbs="breadcrumbs"
    >
      <template v-if="editPermission && isEditPage || createPermission && isCreatePage" #actions>
        <a-col :span="14" class="d-flex justify-content-end" style="gap: 10px;">
          <a-button
            type="primary"
            size="large"
            :disabled="loading"
            style="font-size: 16px; font-weight: 300; border-radius: 2px;"
            @click="save"
          >
            {{ currentDeeplinkId ? 'Save' : 'Create' }}
          </a-button>
        </a-col>
      </template>
    </HeadSection>
    <MainContainer
      class="d-flex flex-column"
      style="gap: 20px;"
      permissionOfSection="deeplinks"
      :loading="loading"
      :isBlockStyle="true"
    >
      <a-form-model :model="form" :rules="rules" ref="form" class="d-flex flex-wrap" style="gap: 20px;">
        <div class="d-flex flex-column" style="gap: 20px;">
          <ContentBlock style="width: 100%; min-width: 500px; max-width: 500px">
            <template #title>
              <span class="d-block font-weight-bold font-size-20">General info</span>
            </template>
            <template #content>
              <a-row style="width: 100%;">
                <a-col>
                  <Readonly
                    :show="isOnlyView"
                    title="Deeplink name"
                    :value="form.title"
                    class="mb-4"
                  >
                    <a-form-model-item label="Name" prop="title" :colon="false">
                      <a-input
                        v-model="form.title"
                        placeholder="Deeplink name"
                        size="large"
                        :class="{ 'transparent-input transparent-input__view-mode' : isOnlyView }"
                        :disabled="loading || isOnlyView"
                      />
                    </a-form-model-item>
                  </Readonly>
                </a-col>
                <a-col>
                  <Readonly
                    :show="isOnlyView"
                    title="Description"
                    :value="form.description ? form.description : '—'"
                    style="align-self: flex-start;"
                  >
                    <a-form-model-item label="Description" prop="description" :colon="false">
                      <a-textarea
                        v-model="form.description"
                        placeholder="Description"
                        size="large"
                        :rows="4"
                        :class="{ 'transparent-input transparent-input__view-mode' : isOnlyView }"
                        :disabled="loading || isOnlyView"
                      />
                    </a-form-model-item>
                  </Readonly>
                </a-col>
                <a-row>
                  <div v-if="!isOnlyView" class="d-flex align-items-center" style="gap: 5px">
                    <a-form-model-item prop="is_auth_only" :colon="false" class="mb-0">
                      <a-checkbox
                        v-model="form.is_frequently_used"
                        :disabled="isOnlyView"
                      >
                        Mark as frequaently used
                      </a-checkbox>
                    </a-form-model-item>
                    <a-tooltip
                      placement="top"
                      arrow-point-at-center
                      :overlayStyle="{ 'white-space': 'normal', 'min-width': '240px' }"
                      :get-popup-container="getPopupContainer"
                    >
                      <template slot="title">
                        <span class="font-weight-light">This deeplink will be pinned to top in deeplink listing</span>
                      </template>
                      <a-icon class="d-flex" type="info-circle" />
                    </a-tooltip>
                  </div>
                </a-row>
              </a-row>
            </template>
          </ContentBlock>
          <ContentBlock style="width: 100%; min-width: 500px;">
            <template #title>
              <span class="d-block font-weight-bold font-size-20">App and version</span>
            </template>
            <template #content>
              <div class="d-flex align-items-center justify-content-between" style="width: 100%; gap: 20px;">
                <Readonly
                  :show="isOnlyView"
                  title="App"
                  :value="currentApp?.name"
                  style="align-self: flex-start; width: 100%;"
                >
                  <template #before>
                    <div v-if="currentApp?.platform" class="universal-select__option__content-icon">
                      <a-icon v-if="currentApp.platform.toLowerCase() === 'android'" type="android" class="text-success" />
                      <a-icon v-if="currentApp.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
                      <a-icon v-if="currentApp.platform.toLowerCase() === 'web'" type="global" class="text-default" />
                    </div>
                  </template>
                  <a-form-model-item label="App" prop="application_id" :colon="false">
                    <UniversalSelect
                      v-model="form.application_id"
                      style="width: 100%; min-width: 180px;"
                      :options="appsOptions"
                      placeholder="App"
                      :showSearch="true"
                      :disabled="loading"
                    />
                  </a-form-model-item>
                </Readonly>
                <Readonly
                  :show="isOnlyView"
                  title="Min version"
                  :value="form.min_app_version ? form.min_app_version : '—'"
                  style="width: 100%;"
                >
                  <a-form-model-item label="Available from (version)" prop="min_app_version" :colon="false">
                    <a-input
                      v-model="form.min_app_version"
                      placeholder="Version"
                      size="large"
                      :class="{ 'transparent-input transparent-input__view-mode' : isOnlyView }"
                      :disabled="loading || isOnlyView"
                    />
                  </a-form-model-item>
                </Readonly>
              </div>
            </template>
          </ContentBlock>
        </div>
        <ContentBlock
          style="min-width: 500px; height: fit-content; flex: 1;"
          :class="{
            'create-deeplink__example-block' : !isOnlyView,
          }"
        >
          <template #title>
            <span class="d-block font-weight-bold font-size-20">Example</span>
          </template>
          <template #content style="height: 100%; flex: 1 1 auto;">
            <div class="create-deeplink__container">
              <a-form-model-item prop="examples" required :colon="false" class="d-block">
                <label class="d-flex align-items-center font-weight-bold" style="gap: 10px;">
                  <span><span v-if="!isOnlyView" style="color: red;">*</span> Examples</span>
                  <a-tooltip
                    placement="top"
                    arrow-point-at-center
                    :overlayStyle="{ 'white-space': 'normal', 'min-width': '240px' }"
                    :get-popup-container="getPopupContainer"
                  >
                    <template v-if="form.examples.length === 5" slot="title">
                      <span class="font-weight-light">You have reached the maximum number of example</span>
                    </template>
                    <span
                      v-if="!isOnlyView"
                      class="add-btn"
                      :class="{ 'add-btn__disabled' : form.examples.length === 5 }"
                      @click.stop="addExample()"
                    >
                      + Add
                    </span>
                  </a-tooltip>
                </label>
                <div class="d-flex flex-column my-2">
                  <draggable
                    v-model="form.examples"
                    v-bind="dragOptions"
                    handle=".handle"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <transition-group>
                      <div
                        v-for="(example, index) in form.examples"
                        :key="example.id"
                        class="d-flex align-items-center"
                        style="gap: 10px;"
                      >
                        <div class="d-flex align-items-center" style="width: 100%;">
                          <a-icon
                            v-if="!isOnlyView"
                            type="menu"
                            class="drag-icon handle create-deeplink__example__icon create-deeplink__example__icon__hide mr-2"
                            :class="{'create-deeplink__example__icon__show' : form.examples.length > 1}"
                          />
                          <div class="d-flex align-items-center" style="width: 100%; gap: 10px;">
                            <Readonly
                              :show="isOnlyView"
                              title=""
                              :value="form.examples[index].source"
                              class="mb-4"
                              style="width: 100%"
                              breakAll
                              hideAfter
                            >
                              <template #before>
                                <a-icon
                                  type="copy"
                                  class="font-size-18 text-primary"
                                  style="margin-top: 10px;"
                                  @click.stop="clipboardHandle(form.examples[index].source)"
                                />
                              </template>
                              <a-form-model-item
                                :prop="`examples.${index}.source`"
                                :rules="[
                                  { required: true, message: 'Example is required', trigger: ['blur', 'change'] }
                                ]"
                                :colon="false"
                                style="width: 100%;"
                              >
                                <a-input
                                  v-model="form.examples[index].source"
                                  placeholder="Example deeplink"
                                  size="large"
                                  :disabled="loading"
                                >
                                  <a-icon
                                    slot="addonBefore"
                                    type="copy"
                                    class="font-size-18"
                                    :class="form.examples[index].source.length ? 'text-primary' : 'text-default cursor-default'"
                                    @click.stop="clipboardHandle(form.examples[index].source)"
                                  />
                                </a-input>
                              </a-form-model-item>
                            </Readonly>
                            <a-popconfirm
                              placement="left"
                              :disabled="loading"
                              title="Are you sure you want to delete this example?"
                              @confirm="() => deleteExample(index)"
                            >
                              <a-icon v-if="!isOnlyView" class="font-size-18 text-danger delete-icon create-deeplink__example__icon" type="delete" />
                            </a-popconfirm>
                          </div>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </a-form-model-item>
              <div class="create-deeplink__container__params">
                <Readonly
                  :show="isOnlyView"
                  title="Params description"
                  :value="form.params_description ? form.params_description : '—'"
                  style="width: 100%; max-width: 500px;"
                  breakAll
                >
                  <a-form-model-item
                    v-if="form.examples.length"
                    label="Params description"
                    prop="params_description"
                    :colon="false"
                  >
                    <a-textarea
                      v-model="form.params_description"
                      placeholder="Params description"
                      size="large"
                      :rows="4"
                      :class="{ 'transparent-input transparent-input__view-mode' : isOnlyView }"
                      :disabled="loading || isOnlyView"
                    />
                  </a-form-model-item>
                </Readonly>
              </div>
            </div>
          </template>
        </ContentBlock>
      </a-form-model>
      <div v-if="deletePermission && currentDeeplinkId && !isOnlyView" class="mt-5">
        <div
          class="d-flex align-items-center text-danger cursor-pointer hover-underline"
          style="width: fit-content;"
          @click="toggleShowDeleteModal(true)"
        >
          <a-icon type="delete" class="mr-2" />
          <span>Delete deeplink</span>
        </div>
      </div>
    </MainContainer>
    <DefaultModal
      :show="showDeleteModal"
      title="Delete deeplink"
      text="Are you sure you want to delete this deeplink?"
      description="All associated data will be lost"
      :actionButton="{
        text: 'Delete',
        type: 'danger',
      }"
      :loading="loading"
      @close="toggleShowDeleteModal"
      @action="deleteDeeplink"
    />
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import { getErrorMessage } from '@/views/apps/fortune-wheel/composables/useGetErrorMessage.js'
import { getRandomId } from '@/composables/useGetRandomId.js'
import HeadSection from '@/components/main/headSection'
import MainContainer from '@/components/main/mainContainer'
import ContentBlock from '@/components/main/contentBlock'
import Readonly from '@/components/custom/readonlyMode/readonly.vue'
import UniversalSelect from '@/components/custom/filters/universalSelect.vue'
import DefaultModal from '@/components/custom/modals/defaultModal'
import draggable from 'vuedraggable'

export default {
  props: {
    viewPermission: {
      type: Boolean,
      default: false,
    },
    createPermission: {
      type: Boolean,
      default: false,
    },
    editPermission: {
      type: Boolean,
      default: false,
    },
    deletePermission: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeadSection,
    MainContainer,
    ContentBlock,
    Readonly,
    UniversalSelect,
    DefaultModal,
    draggable,
  },
  data: () => ({
    currentDeeplinkId: null,
    form: {
      title: '',
      description: '',
      is_frequently_used: false,
      application_id: undefined,
      min_app_version: undefined,
      examples: [],
      params_description: '',
    },
    appsOptions: [],
    showDeleteModal: false,
    drag: false,
    loading: false,
  }),
  watch: {
    userLoading: {
      handler(val) {
        if (this.isOnlyView) {
          return
        }
        if (!val) {
          this.checkPermissions()
        }
      },
      deep: true,
    },
    '$route.path': {
      handler(val) {
        this.checkPermissions()
      },
      deep: true,
    },
  },
  computed: {
    userLoading() {
      return this.$store.state?.user?.loading || false
    },
    dragOptions() {
      return {
        animation: 300,
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    isOnlyView() {
      return this.$route.name === 'view-deeplink'
    },
    isCreatePage() {
      return this.$route.name === 'create-deeplink'
    },
    isEditPage() {
      return this.$route.name === 'edit-deeplink'
    },
    isEditDeeplink() {
      return !!this.currentDeeplinkId
    },
    breadcrumbs() {
      return [
        { name: 'Deeplinks', link: '/settings/deeplinks' },
        { name: this.isOnlyView ? 'View deeplink' : this.isEditDeeplink ? 'Edit deeplink' : 'Create deeplink', link: undefined },
      ]
    },
    rules() {
      return {
        title: [{ required: true, message: 'Deeplink name is required', trigger: ['blur', 'change'] }],
        application_id: [{ required: true, message: 'Application is required', trigger: ['blur', 'change'] }],
        examples: [{ type: 'array', required: true, message: 'Please add example', trigger: 'change' }],
      }
    },
    currentApp() {
      if (this.appsOptions?.length) {
        const app = this.appsOptions.find(app => app.value === this.form.application_id)
        return app
      }
      return null
    },
  },
  methods: {
    checkPermissions() {
      if (!this.$store.state?.user?.id) {
        return
      }
      if (!this.viewPermission) {
        this.$router.push('/settings/deeplinks')
        this.$notification.error({
          message: 'You do not have sufficient rights for this section, contact the administrator',
        })
        return
      }
      if (!this.editPermission || !this.createPermission) {
        if (!this.createPermission && this.isCreatePage) {
          this.$notification.error({
            message: 'You do not have sufficient rights for this section, contact the administrator',
          })
          this.$router.push('/settings/deeplinks')
          return
        }
        if (!this.editPermission && this.isEditPage) {
          this.$notification.error({
            message: 'You do not have sufficient rights for this section, contact the administrator',
          })
          this.$router.push(`/settings/deeplinks/${this.$route?.params?.id}/view`)
        }
      }
    },
    getPopupContainer(trigger) {
      return trigger.parentElement
    },
    clipboardHandle(text) {
      if (!text?.length) {
        return
      }
      this.$clipboard(text)
      this.$notification.success({
        message: 'Example successfully copied to clipboard',
      })
    },
    toggleShowDeleteModal(status = false) {
      this.showDeleteModal = status
    },
    addExample(source = '') {
      if (this.form.examples?.length === 5) {
        return
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
      let arrIds = []
      if (this.form.examples?.length) {
        arrIds = this.form.examples.map(example => example.id)
      }
      this.form.examples.push({ id: getRandomId(arrIds), source })
    },
    deleteExample(index) {
      this.form.examples.splice(index, 1)
    },
    getFormData() {
      const formData = {
        title: this.form.title,
        application_id: this.form.application_id,
        is_frequently_used: this.form.is_frequently_used,
        examples: this.form.examples.map(example => example.source),
      }
      if (this.form.description) {
        formData.description = this.form.description
      }
      if (this.form.min_app_version) {
        formData.min_app_version = this.form.min_app_version
      }
      if (this.form.params_description) {
        formData.params_description = this.form.params_description
      }
      return formData
    },
    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const data = this.getFormData()
          try {
            this.loading = true
            let response
            if (this.currentDeeplinkId) {
              const url = `/admin/deeplinks/${this.currentDeeplinkId}`
              response = await apiClient.put(url, data)
            } else {
              const url = 'admin/deeplinks'
              response = await apiClient.post(url, data)
            }
            if (response?.data?.success) {
              this.$notification.success({
                message: this.currentDeeplinkId ? 'Deeplink edited successfully' : 'Deeplink successfully created',
              })
              this.$router.push('/settings/deeplinks')
            } else {
              getErrorMessage(response?.data)
            }
          } finally {
            this.loading = false
          }
        } else {
          return false
        }
      })
      return false
    },
    async deleteDeeplink() {
      try {
        this.loading = true
        const url = `/admin/deeplinks/${this.currentDeeplinkId}`
        const response = await apiClient.delete(url)
        if (response?.data?.success) {
          this.$notification.success({
            message: 'Deeplink removed successfully',
          })
          this.$router.push('/settings/deeplinks')
        } else {
          getErrorMessage(response?.data)
        }
      } finally {
        this.loading = false
      }
    },
    async getOptions() {
      try {
        this.loading = true
        const url = 'admin/applications'
        const response = await apiClient.get(url)
        if (response?.data?.data) {
          this.appsOptions = response?.data?.data?.map(application => {
            return { name: application.name, value: application.application_id, platform: application.platform }
          })
        } else {
          getErrorMessage(response?.data)
        }
      } finally {
        this.loading = false
      }
    },
    async getDeeplinkParams() {
      try {
        this.loading = true
        const url = `admin/deeplinks/${this.currentDeeplinkId}`
        const response = await apiClient.get(url)
        if (response?.data?.success) {
          this.setDeeplinkData(response.data.data)
        } else {
          if (response?.status === 404) {
            this.$router.push('/settings/deeplinks')
          }
          getErrorMessage(response?.data)
        }
      } finally {
        this.loading = false
      }
    },
    setDeeplinkData(data) {
      this.form.title = data?.title || ''
      this.form.description = data?.description || ''
      this.form.is_frequently_used = data?.is_frequently_used || false
      this.form.application_id = data?.application?.application_id || undefined
      this.form.min_app_version = data?.min_app_version || ''
      this.form.params_description = data?.params_description || ''
      if (data?.examples?.length) {
        data.examples.forEach(example => {
          this.addExample(example)
        })
      }
    },
  },
  async created() {
    this.checkPermissions()
    const requests = [this.getOptions()]
    this.getOptions()
    if (this.$route?.params?.id) {
      this.currentDeeplinkId = Number(this.$route.params.id)
      requests.push(this.getDeeplinkParams())
    }

    try {
      this.loading = true
      await Promise.all(requests)
    } finally {
      this.loading = false
    }
  },
}
</script>

<style lang="scss">
.create-deeplink {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1 1 auto;
    &__params {
      width: 100%;
      flex: 1 1 auto;
      display: flex;
      align-items: flex-end;
    }
  }
  &__example-block {
    max-width: 500px;
    min-height: 386px;
  }
  &__example {
    transition: width 2s ease;
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      height: 40px;
      width: 20px;
      min-width: 20px;
      transition: all .3s ease;
      &__hide {
        width: 0;
        min-width: 0;
      }
      &__show {
        width: 20px;
        min-width: 20px;
      }
    }
  }
}
</style>
