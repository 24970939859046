const getRandomId = (array = []) => {
  // Создаем уникальный id
  if (!array.length) {
    return Math.round(Math.random() * 10000)
  }

  let newId = null
  do {
    newId = Math.round(Math.random() * 10000)
  } while (array.find(el => el?.uniqueId === newId))

  return newId
}

export { getRandomId }
