<template>
  <a-modal
    v-model="visible"
    :title="title"
    :ok-text="actionButton.text"
    :ok-type="actionButton.type"
    cancel-text="Cancel"
    :destroyOnClose="true"
    :confirmLoading="loading"
    @cancel="closeModal"
    @ok="action"
  >
    <div class="d-flex flex-column justify-content-center">
      <p v-if="text" class="font-size-18 font-weight-bold">{{ text }}</p>
      <p v-if="description" class="font-size-18 mt-1">{{ description }}</p>
    </div>
  </a-modal>
</template>

<script>

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    actionButton: {
      type: Object,
      default: () => ({
        text: 'Confirm',
        type: 'primary',
      }),
    },
  },
  data: () => ({
    visible: false,
  }),
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.visible = true
        } else {
          this.visible = false
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    action() {
      this.$emit('action')
    },
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
